import { Controller } from '@hotwired/stimulus';
import { gsap } from 'gsap';
import {
  autoUpdate,
  computePosition,
  offset,
  shift,
  flip,
  size
} from '@floating-ui/dom';

let dropdown;

export default class extends Controller {
  static targets = ['trigger', 'menu'];
  static classes = ['open', 'wrapper'];
  static values = {
    placement: String,
    isOpen: {
      type: Boolean,
      default: false
    },
    cloneMenu: {
      type: Boolean,
      default: true
    }
  };

  connect() {
    this.originalParent = this.element;
    this.menuTargetId = this.menuTarget.getAttribute('id');
    this.menuElement = document.getElementById(this.menuTargetId);
    this.menuWrapperElement = this.menuElement.firstElementChild;
    this.triggerElement =
      this.triggerTarget.firstElementChild &&
      this.triggerTarget.firstElementChild.tagName === 'BUTTON'
        ? this.triggerTarget.firstElementChild
        : this.triggerTarget;

    this.triggerElement.setAttribute('aria-haspopup', 'true');
    this.triggerElement.setAttribute('aria-expanded', 'false');
    this.triggerElement.addEventListener('click', this.toggle);
  }

  toggle = (event) => {
    event.stopPropagation();
    this.isOpenValue ? this.hideMenu() : this.showMenu();
  };

  showMenu() {
    if (dropdown) dropdown.hideMenu();
    dropdown = this;

    if (this.cloneMenuValue) {
      document.body.appendChild(this.menuElement);
    }

    this.element.classList.add(...this.wrapperClasses);
    this.menuElement.classList.remove(...this.openClasses);
    this.isOpenValue = true;
    this.triggerElement.setAttribute('aria-expanded', 'true');
    this.menuElement.focus();
    this.cleanupAutoUpdate = autoUpdate(
      this.triggerElement,
      this.menuElement,
      () => this.update()
    );

    gsap.fromTo(
      this.menuWrapperElement,
      { opacity: 0, scale: 0.95 },
      {
        opacity: 1,
        scale: 1,
        duration: 0.15,
        ease: 'expo.out'
      }
    );

    document.addEventListener('click', this.outsideClickHandler);
    document.addEventListener('keydown', this.escapeKeyHandler);
    this.menuElement.addEventListener('click', this.handleMenuClick);
  }

  handleMenuClick = (event) => {
    const interactiveElement = event.target.closest(
      'a, [role="option"], button'
    );
    if (interactiveElement) {
      this.hideMenu();
    }
  };

  hideMenu({ skipAnimation = false } = {}) {
    if (!this.isOpenValue) return;

    gsap.to(this.menuWrapperElement, {
      opacity: 0,
      scale: 0.95,
      duration: skipAnimation ? 0 : 0.1,
      ease: 'power3.in',
      onComplete: () => {
        gsap.set(this.menuWrapperElement, { clearProps: 'all' });
        this.menuElement.classList.add(...this.openClasses);

        if (this.cloneMenuValue) {
          this.originalParent.appendChild(this.menuElement);
        }

        this.element.classList.remove(...this.wrapperClasses);
        this.isOpenValue = false;
        this.triggerElement.setAttribute('aria-expanded', 'false');
        this.triggerElement.focus();
        this.cleanupAutoUpdate?.();
        document.removeEventListener('click', this.outsideClickHandler);
        document.removeEventListener('keydown', this.escapeKeyHandler);
        this.menuElement.removeEventListener('click', this.handleMenuClick);
      }
    });
  }

  update = async () => {
    const applySize = ({ availableWidth, availableHeight }) => {
      const firstChild = this.menuElement.firstElementChild;
      if (firstChild && firstChild.style) {
        Object.assign(firstChild.style, {
          maxWidth: `${availableWidth}px`,
          maxHeight: `${availableHeight}px`,
          overflowY: 'auto'
        });
      }
    };

    const middleware = [
      offset(4),
      shift({ mainAxis: 4 }),
      flip(),
      size({ apply: applySize })
    ];

    const positionConfig = {
      placement: this.placementValue || 'bottom-end',
      middleware: middleware
    };

    const { x, y, strategy } = await computePosition(
      this.triggerElement,
      this.menuElement,
      positionConfig
    );

    Object.assign(this.menuElement.style, {
      left: `${x}px`,
      top: `${y}px`,
      position: strategy,
      zIndex: 9999
    });
  };

  outsideClickHandler = (event) => {
    if (
      !this.element.contains(event.target) &&
      !this.menuElement.contains(event.target)
    ) {
      this.hideMenu();
    }
  };

  escapeKeyHandler = (event) => {
    if (event.key === 'Escape') {
      this.hideMenu();
    }
  };

  disconnect() {
    this.hideMenu({ skipAnimation: true });
    this.triggerElement.removeEventListener('click', this.toggle);
  }
}
