const Pagy = (() => {
  // Initialize the combo navigation helpers
  const initComboNav = (element, [urlToken, trimParam]) => {
    const inputElement = element.querySelector('input');
    const initialInputValue = inputElement.value;

    const navigateTo = (value) => {
      let pageValue = value;
      let url = urlToken.replace(/__pagy_page__/, pageValue);

      // Trim URL if needed
      if (typeof trimParam === 'string' && pageValue === '1') {
        url = trimUrlParam(url, trimParam);
      }

      // Use Turbo if available, otherwise fallback to traditional navigation
      if (typeof Turbo !== 'undefined' && Turbo.visit) {
        Turbo.visit(url);
      } else {
        window.location.href = url;
      }
    };

    // Event listener to handle user interactions
    inputElement.addEventListener('focusout', () => {
      if (inputElement.value !== initialInputValue) {
        navigateTo(inputElement.value);
      }
    });

    inputElement.addEventListener('keypress', (event) => {
      if (event.key === 'Enter') {
        navigateTo(inputElement.value);
      }
    });
  };

  // Helper function to trim pagination URL parameters
  const trimUrlParam = (url, param) =>
    url.replace(new RegExp(`[?&]${param}=1\\b(?!&)|\\b${param}=1&`), '');

  // Public initialization method
  const init = (arg) => {
    const target = arg instanceof Element ? arg : document;
    const elements = target.querySelectorAll('[data-pagy]');

    elements.forEach((element) => {
      try {
        const data = JSON.parse(atob(element.getAttribute('data-pagy')));
        const [keyword, ...args] = data;

        if (keyword === 'combo') {
          initComboNav(element, args);
        } else {
          console.warn(
            `Skipped Pagy.init() for: %o\nUnknown keyword '%s'`,
            element,
            keyword
          );
        }
      } catch (err) {
        console.warn('Skipped Pagy.init() for: %o\n%s', element, err);
      }
    });
  };

  // Expose the public interface
  return { init, version: '7.0.11' };
})();

export default Pagy;
