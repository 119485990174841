import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['menu'];
  static classes = ['open'];

  toggle() {
    this.menuTarget.classList.toggle(this.openClass);
  }
}
