import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = [
    'wrapper',
    'input',
    'displayValueText',
    'overlay',
    'content'
  ];
  static classes = ['toggle', 'placeholder', 'inputText'];
  static values = {
    autocompleteUrl: String,
    placeholders: Array
  };

  connect() {
    this.timeout = null;
    this.abortController = null;
    this.placeholderInterval = null;
    this.index = 0;
    this.selectedIndex = -1;
    this.placeholders = this.placeholdersValue;

    this.handleKeydownBound = this.handleKeydown.bind(this);
    this.handleMouseDownBound = this.handleMouseDown.bind(this);
    this.handleMouseUpBound = this.handleMouseUp.bind(this);

    this.setupAutosuggest();
    this.updateDisplayValue();
  }

  disconnect() {
    this.stopCycling();
  }

  setupAutosuggest() {
    this.inputTarget.addEventListener('input', () => {
      const searchTerm = this.inputTarget.value;
      this.performSearch(searchTerm);
      this.updateDisplayValue();
    });
  }

  async performSearch(searchTerm) {
    clearTimeout(this.timeout);

    if (this.abortController) {
      this.abortController.abort();
    }

    this.timeout = setTimeout(async () => {
      this.abortController = new AbortController();

      try {
        const response = await get(this.autocompleteUrlValue, {
          query: { search_term: searchTerm },
          responseKind: 'turbo-stream',
          signal: this.abortController.signal
        });

        if (!response.ok) {
          console.error('Autocomplete request failed:', response);
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Search error:', error);
        }
      }
    }, 150);
  }

  open() {
    this.element.classList.add(this.toggleClass);
    this.inputTarget.focus();

    const length = this.inputTarget.value.length;
    this.inputTarget.setSelectionRange(length, length);

    this.performSearch(this.inputTarget.value);
    this.stopCycling();

    document.addEventListener('keydown', this.handleKeydownBound);
    document.addEventListener('mousedown', this.handleMouseDownBound);
    document.addEventListener('mouseup', this.handleMouseUpBound);

    this.isMouseDownInside = false;
    this.selectedIndex = -1;
  }

  close() {
    this.element.classList.remove(this.toggleClass);
    this.updateDisplayValue();

    document.removeEventListener('keydown', this.handleKeydownBound);
    document.removeEventListener('mousedown', this.handleMouseDownBound);
    document.removeEventListener('mouseup', this.handleMouseUpBound);

    this.clearSelection();
  }

  clear() {
    if (this.inputTarget.value) {
      this.inputTarget.value = '';
      this.updateDisplayValue();
      this.performSearch(this.inputTarget.value);
      this.inputTarget.focus();
    } else {
      this.close();
    }
  }

  updateDisplayValue() {
    const value = this.inputTarget.value.trim();
    this.displayValueTextTarget.classList.remove(
      this.placeholderClass,
      this.inputTextClass
    );

    if (value) {
      this.displayValueTextTarget.textContent = value;
      this.displayValueTextTarget.classList.add(this.inputTextClass);
      this.stopCycling();
    } else {
      this.index = 0;
      this.displayValueTextTarget.textContent = this.placeholders[this.index];
      this.displayValueTextTarget.classList.add(this.placeholderClass);
      this.startCycling();
    }
  }

  handleKeydown(event) {
    switch (event.key) {
      case 'Escape':
        this.close();
        break;
      case 'ArrowDown':
        this.navigateSuggestions(1);
        event.preventDefault();
        break;
      case 'ArrowUp':
        this.navigateSuggestions(-1);
        event.preventDefault();
        break;
      case 'Enter':
        const handled = this.selectSuggestion();
        if (handled) event.preventDefault();
        break;
      default:
        break;
    }
  }

  navigateSuggestions(direction) {
    const suggestions = this.getSuggestions();
    if (suggestions.length === 0) return;

    if (this.selectedIndex >= 0 && this.selectedIndex < suggestions.length) {
      const prevSuggestion = suggestions[this.selectedIndex];
      prevSuggestion.setAttribute('aria-selected', 'false');
    }

    this.selectedIndex =
      (this.selectedIndex + direction + suggestions.length) %
      suggestions.length;

    const currentSuggestion = suggestions[this.selectedIndex];
    currentSuggestion.setAttribute('aria-selected', 'true');
  }

  selectSuggestion() {
    const suggestions = this.getSuggestions();
    if (this.selectedIndex >= 0 && this.selectedIndex < suggestions.length) {
      const currentSuggestion = suggestions[this.selectedIndex];
      currentSuggestion.click();
      return true;
    }

    return false;
  }

  clearSelection() {
    const suggestions = this.getSuggestions();
    suggestions.forEach((suggestion) => {
      suggestion.setAttribute('aria-selected', 'false');
    });
    this.selectedIndex = -1;
  }

  getSuggestions() {
    return this.element.querySelectorAll('[data-search-suggestion]');
  }

  handleMouseDown(event) {
    this.isMouseDownInside =
      this.contentTarget.contains(event.target) ||
      this.contentTarget === event.target;
  }

  handleMouseUp(event) {
    const clickedInside =
      this.contentTarget.contains(event.target) ||
      this.contentTarget === event.target;

    if (!this.isMouseDownInside && !clickedInside) {
      this.close();
    }

    this.isMouseDownInside = false;
  }

  startCycling() {
    if (this.placeholderInterval) return;

    this.placeholderInterval = setInterval(() => {
      this.fadeOut().then(() => {
        this.index = (this.index + 1) % this.placeholders.length;
        this.displayValueTextTarget.textContent = this.placeholders[this.index];
        this.fadeIn();
      });
    }, 5000);
  }

  stopCycling() {
    if (this.placeholderInterval) {
      clearInterval(this.placeholderInterval);
      this.placeholderInterval = null;
    }
  }

  fadeOut() {
    return new Promise((resolve) => {
      this.displayValueTextTarget.classList.add('opacity-0');
      setTimeout(resolve, 300);
    });
  }

  fadeIn() {
    this.displayValueTextTarget.offsetHeight;
    this.displayValueTextTarget.classList.remove('opacity-0');
  }
}
