import { Controller } from '@hotwired/stimulus';
import { gsap } from 'gsap';

export default class extends Controller {
  /**
   * Value definitions for the controller.
   * @property {Boolean} persistent - Determines if the toast should stay visible without auto-dismissing.
   * @property {Boolean} autoshow - Determines if the toast should show automatically upon connection.
   * @property {Number} duration - The duration in milliseconds for which the toast remains visible before dismissing.
   */
  static values = {
    persistent: Boolean,
    autoshow: Boolean,
    duration: Number
  };

  connect() {
    if (this.autoshowValue) {
      this.show();
    }
  }

  /**
   * Shows the toast
   */
  show = () => {
    this.animateShow();
    if (!this.persistentValue) {
      setTimeout(() => this.dismiss(), this.durationValue);
    }
  };

  /**
   * Animates the showing of the toast.
   */
  animateShow = () => {
    gsap.fromTo(
      this.element,
      { opacity: 0, scale: 0.95 },
      {
        opacity: 1,
        scale: 1,
        duration: 0.2,
        delay: 0.1,
        ease: 'back.out(1.1)'
      }
    );
  };

  /**
   * Dismisses the toast
   */
  dismiss = () => {
    this.animateDismiss();
  };

  /**
   * Collects all preceding siblings of an element.
   * @param {Element} element - The element to start collecting siblings from.
   * @returns {Array} - An array of sibling elements.
   */
  collectPrecedingSiblings(element) {
    let siblings = [];
    let sibling = element.previousElementSibling; // Start with the previous sibling
    while (sibling) {
      siblings.unshift(sibling); // Add to the beginning of the array to maintain order
      sibling = sibling.previousElementSibling; // Move to the next preceding sibling
    }
    return siblings;
  }

  /**
   * Animates the dismissal of the toast.
   */
  animateDismiss = () => {
    let precedingSiblings = this.collectPrecedingSiblings(this.element);
    if (precedingSiblings.length > 0) {
      this.applyTransformToSiblings(precedingSiblings);
    }

    // Animation for the current toast
    gsap.to(this.element, {
      duration: 0.2,
      opacity: 0,
      scale: 0.85,
      ease: 'power2.in',
      onComplete: () => {
        this.element.remove();
      }
    });
  };

  /**
   * Applies a transform to all sibling elements to animate them downwards.
   * @param {Element} element - The element to start applying the transformations from.
   */
  applyTransformToSiblings(elements) {
    elements.forEach((sibling) => {
      gsap.to(sibling, {
        duration: 0.2,
        y: this.element.offsetHeight,
        ease: 'back.in(1.1)',
        onComplete: () => {
          if (sibling) {
            gsap.set(sibling, { clearProps: 'transform' });
          }
        }
      });
    });
  }

  /**
   * Resets the transform properties of all sibling elements.
   * @param {Element} element - The element to start resetting the transformations from.
   */
  resetSiblingsTransform(element) {
    let sibling = element;
    while (sibling) {
      gsap.set(sibling, { clearProps: 'transform' });
      sibling = sibling.previousElementSibling;
    }
  }
}
