import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { enter, leave } from 'el-transition';

export default class extends Controller {
  static targets = ['container', 'content', 'overlay'];
  static values = {
    advanceUrl: String,
    allowedClickOutsideSelector: {
      type: String,
      default: '#modal-content'
    },
    closeOnOutsideClick: Boolean,
    closeOnEscape: Boolean
  };

  connect() {
    this.showModal();
    this.turboFrame = this.element.closest('turbo-frame');
    window.addEventListener('popstate', () => {
      if (this.#hasHistoryAdvanced()) this.#resetModalElement();
    });
    window.modal = this;

    // Track if a click starts inside the modal
    this.isMouseDownInsideModal = false;

    // Add event listeners for mouse events
    if (this.closeOnOutsideClickValue) {
      document.addEventListener('mousedown', this.#onMouseDown.bind(this));
      document.addEventListener('mouseup', this.#onMouseUp.bind(this));
    }

    if (this.closeOnEscapeValue) {
      document.addEventListener('keyup', this.closeWithKeyboard.bind(this));
    }
  }

  disconnect() {
    window.modal = undefined;

    // Remove event listeners
    if (this.closeOnOutsideClickValue) {
      document.removeEventListener('mousedown', this.#onMouseDown.bind(this));
      document.removeEventListener('mouseup', this.#onMouseUp.bind(this));
    }

    if (this.closeOnEscapeValue) {
      document.removeEventListener('keyup', this.closeWithKeyboard.bind(this));
    }
  }

  #onMouseDown(e) {
    this.isMouseDownInsideModal =
      this.contentTarget.contains(e.target) || this.contentTarget == e.target;
  }

  #onMouseUp(e) {
    let clickedInsideModal =
      this.contentTarget.contains(e.target) || this.contentTarget == e.target;
    let clickedAllowedSelector =
      e.target.closest(this.allowedClickOutsideSelectorValue) != null;

    if (
      !this.isMouseDownInsideModal &&
      !clickedInsideModal &&
      !clickedAllowedSelector
    ) {
      this.hideModal();
    }

    // Reset the state
    this.isMouseDownInsideModal = false;
  }

  showModal() {
    enter(this.overlayTarget);
    enter(this.contentTarget);
    this.#lockBodyScroll();

    if (this.advanceUrlValue && !this.#hasHistoryAdvanced()) {
      this.#setHistoryAdvanced();
      history.pushState({}, '', this.advanceUrlValue);
    }
  }

  hideModal() {
    if (this.hidingModal) return;
    this.hidingModal = true;

    let event = new Event('modal:closing', { cancelable: true });
    this.turboFrame.dispatchEvent(event);
    if (event.defaultPrevented) return;

    this.#resetModalElement();
    this.#unlockBodyScroll();

    event = new Event('modal:closed', { cancelable: false });
    this.turboFrame.dispatchEvent(event);

    if (this.#hasHistoryAdvanced()) history.back();
  }

  hide() {
    this.hideModal();
  }

  refreshPage() {
    window.Turbo.visit(window.location.href, { action: 'replace' });
  }

  submitEnd(e) {
    if (e.detail.success) this.hideModal();
  }

  closeWithKeyboard(e) {
    if (e.code == 'Escape' && this.closeOnEscapeValue) this.hideModal();
  }

  #resetModalElement() {
    leave(this.overlayTarget);
    leave(this.contentTarget).then(() => {
      this.turboFrame.removeAttribute('src');
      this.containerTarget.remove();
      this.#resetHistoryAdvanced();
    });
  }

  #hasHistoryAdvanced() {
    return (
      document.body.getAttribute('data-turbo-modal-history-advanced') == 'true'
    );
  }

  #setHistoryAdvanced() {
    return document.body.setAttribute(
      'data-turbo-modal-history-advanced',
      'true'
    );
  }

  #resetHistoryAdvanced() {
    document.body.removeAttribute('data-turbo-modal-history-advanced');
  }

  #lockBodyScroll() {
    document.body.style.overflow = 'hidden';
  }

  #unlockBodyScroll() {
    document.body.style.overflow = 'auto';
  }
}

Turbo.StreamActions.modal = function () {
  const message = this.getAttribute('message');

  if (message == 'hide') window.modal?.hide();
  if (message == 'close') window.modal?.hide();
};
